/* eslint-disable no-console */
// Modules
import React, { useState, useRef, useEffect, Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Geocode from "react-geocode";
import { cloneDeep, sortBy, intersection, isFunction, flatMap, has, isArray } from "lodash";

// React Bootstrap
import Modal from "react-bootstrap/Modal";
import { Form, FormGroup } from "react-bootstrap";

// Styles
import "./loan-officer-search.bootstrap.scss";
import styles from "../custom-widgets/search-suggestions-input.module.scss";

// Components
import LoanOfficerSummary from "./loan-officer-summary";
import GoogleMapSimple from "../google-map/google-map-simple";
import Icon from "../custom-widgets/icon";
import { useLanguageContext } from "../../contexts/language-context";
import NotificationAlert from "../notifications/notification-alert";

// Helper functions
import distance from "../../helpers/geo-data-source";
import isWaFdBankInState from "../../helpers/isWaFdBankInState";
import getAddressComponentsByType from "../../helpers/getAddressComponentsByType";
import getStateCodeFromState from "../../helpers/getStateCodeFromState";
import getStateFromStateCode from "../../helpers/getStateFromStateCode";
import filterBranchLocationsByViewport from "../../helpers/filterBranchLocationsByViewport";

// Google Maps API Key to use for the reverse geocode (address to lat,lng) lookup
const GOOGLE_MAPS_API_KEY = "AIzaSyDfp-9CfXxw-p-tC2tJVSikB5WnFisuYZ4";
/* Maximum number of branch locations to show. Might be useful for more densely covered areas (e.g. Seattle, WA)
 * Not used for state-only searches.
 * Use 0 for no limit.
 */
const MAX_BRANCH_LOCATIONS_TO_SHOW = 25;

/**
 * @typedef PersonalBanker
 * @property {number} id
 * @property {string} EccUsername
 * @property {string} HrLocationCode
 * @property {string} EmailAddress
 * @property {string} FirstName
 * @property {string} JobTitle
 * @property {string} LastName
 * @property {string} NetworkId
 * @property {string} NMLSR
 * @property {string} PhoneNumber
 * @property {Object} ProfilePhoto
 * @property {Object} branch_location
 */

/**
 * @typedef MortgageBanker
 * @property {number} id
 * @property {string} EccUsername
 * @property {string} HrLocationCode
 * @property {string} EmailAddress
 * @property {string} FirstName
 * @property {string} JobTitle
 * @property {string} LastName
 * @property {string} NetworkId
 * @property {string} NMLSR
 * @property {string} PhoneNumber
 * @property {Object} ProfilePhoto
 * @property {Object[]} branch_locations
 */

/**
 * @typedef LoanOfficer
 * @property {number} id
 * @property {string} EccUsername
 * @property {string} HrLocationCode
 * @property {string} EmailAddress
 * @property {string} FirstName
 * @property {string} JobTitle
 * @property {string} LastName
 * @property {string} NetworkId
 * @property {string} NMLSR
 * @property {string} PhoneNumber
 * @property {Object} ProfilePhoto
 * @property {Object[]} branch_locations
 */

/**
 * @typedef BranchLocation
 * @property {number} branch_location.id
 * @property {string} branch_location.EmailAddress
 * @property {string} branch_location.PageURL
 * @property {string} branch_location.PhoneNumber
 * @property {string} branch_location.FaxNumber
 * @property {Object} branch_location.Address
 * @property {string} branch_location.Address.StreetAddress
 * @property {string} branch_location.Address.City
 * @property {string} branch_location.Address.State
 * @property {string} branch_location.Address.ZipCode
 * @property {Object} branch_location.Address.BranchGeoLocation
 * @property {number} branch_location.Address.BranchGeoLocation.Lat
 * @property {number} branch_location.Address.BranchGeoLocation.Lng
 */

/**
 * @typedef GeocodeResult
 * @property {string|null} city
 * @property {string|null} state
 * @property {number|null} lat
 * @property {number|null} lng
 * @property {string|null} errorHtml
 */

/**
 * @typedef StrapiResult
 * @property imgVariableXXL
 * @property imgVariableXL
 * @property imgVariableLG
 * @property imgVariableMD
 * @property imgVariableSM
 * @property imgVariableXS
 * @property {Object} allStrapiLoanOfficers
 * @property {Object[]} allStrapiLoanOfficers.nodes
 */

const LoanOfficerSearch = (props) => {
  const isSpanish = useLanguageContext();

  // Error messages if applicable.
  const [nameSearchErrorMessage, setNameSearchErrorMessage] = useState(null);
  const [locationSearchErrorMessage, setLocationSearchErrorMessage] = useState(null);

  /* Instance of Google Map API. This is populated by the GoogleMapSimple component
     and is used to add InfoWindows when clicking on a marker.
   */
  const [googleMapInstance, setGoogleMapInstance] = useState(null);

  // The object that will be passed to the GoogleMapSimple component as props
  const defaultGoogleMapDataProps = {
    lang: props.language,
    zoom: 4,
    setGoogleMapInstance: setGoogleMapInstance
  };

  // Object to store Google Map data
  const [googleMapData, setGoogleMapData] = useState(defaultGoogleMapDataProps);

  // Get references to the search text inputs so that we can access their values
  const nameSearchInputRef = useRef();
  const locationSearchInputRef = useRef();

  // simple boolean flag if the search has been performed or not
  const [hasPerformedSearch, setHasPerformedSearch] = useState(false);

  // Array that stores branch locations
  const [branchLocations, setBranchLocations] = useState([]);
  // Object that stores an array of loan officers, keyed by branch id.
  const [loanOfficersByBranchId, setLoanOfficersByBranchId] = useState({});

  let searchParams = new URLSearchParams(props.location.search);

  // Keeps track of the open InfoWindow on the Google Map so we can close it when we are done with it
  /**
   *
   * @type {window.google.maps.InfoWindow|null}
   */
  let openGoogleMapInfoWindow = null;

  /**
   * Returns a JSX element containing a numbered green circle with the specified number.
   * @param {Object} props
   * @param {number} props.number
   * @return {JSX.Element}
   * @constructor
   */
  const NumberedGreenCircle = (props) => {
    return (
      <span className="my-3 align-items-center rounded-circle bg-success font-weight-bold text-white text-larger d-flex-center green-circle-number-big">
        {props.number}
      </span>
    );
  };

  /**
   * @type {StrapiResult}
   *
   * NOTE: business rules for appearing on the Loan Officer search results
   *   - Strapi Loan Officers that do NOT a job title of "Personal Banker" and have an ECC Username
   *   - Strapi Mortgage Bankers regardless of job title that have an ECC Username
   *
   * Queries also need to exclude Dummy data (Dummy Branch, Dummy Loan Officer, Dummy Mortgage Banker, etc.)
   */
  const pageData = useStaticQuery(graphql`
    query {
      allStrapiBranchLocations(filter: { Title: { ne: "Dummy Branch" } }) {
        nodes {
          id
          Title
          BranchId
          EmailAddress
          PhoneNumber
          PageURL
          Address {
            City
            Country
            State
            StreetAddress
            ZipCode
            BranchGeoLocation {
              Lat
              Lng
            }
          }
          branch_state {
            StateCode
          }
          branch_city {
            CityName
          }
        }
      }
      allStrapiLoanOfficers(
        filter: { FirstName: { ne: "Dummy" }, LastName: { ne: "LoanOfficer" }, EccUsername: { ne: "" } }
      ) {
        nodes {
          id
          EmailAddress
          FirstName
          LastName
          JobTitle
          NMLSR
          EccUsername
          EmailAddress
          PhoneNumber
          HrLocationCode
          ProfilePhoto {
            childImageSharp {
              gatsbyImageData
            }
          }
          branch_location {
            id
            Title
            EmailAddress
            PageURL
            PhoneNumber
            FaxNumber
            Address {
              StreetAddress
              City
              State
              ZipCode
              BranchGeoLocation {
                Lat
                Lng
              }
            }
          }
        }
      }
      allStrapiMtgBankers(
        filter: {
          FirstName: { ne: "Dummy" }
          LastName: { ne: "MortgageBanker" }
          EccUsername: { ne: "" }
          Active: { eq: true }
        }
      ) {
        nodes {
          id
          EmailAddress
          FirstName
          LastName
          JobTitle
          NMLSR
          EmailAddress
          PhoneNumber
          EccUsername
          HrLocationCode
          Active
          ProfilePhoto {
            childImageSharp {
              gatsbyImageData
            }
          }
          branch_locations {
            id
            Title
            EmailAddress
            PageURL
            PhoneNumber
            FaxNumber
            Address {
              StreetAddress
              City
              State
              ZipCode
              BranchGeoLocation {
                Lat
                Lng
              }
            }
          }
        }
      }
    }
  `);

  /**
   * @type {PersonalBanker[]}
   */
  let strapiLoanOfficers = pageData.allStrapiLoanOfficers.nodes;
  // Filtering LOs and MBs with EccUsername is null here since Gatsby's filtering capabilities don't support more complex logical operators like OR, AND
  strapiLoanOfficers = strapiLoanOfficers.filter((lo) => lo.EccUsername !== null);

  /**
   * @type {MortgageBanker[]}
   */
  let strapiMortgageBankers = pageData.allStrapiMtgBankers.nodes;
  strapiMortgageBankers = strapiMortgageBankers.filter((mb) => mb.EccUsername !== null && mb.Active === true);

  // create an array of branch_locations from the personalBankers' single branch_location to match mortgageBankers
  strapiLoanOfficers.forEach((pb) => {
    let branchLocations = [];
    branchLocations.push(pb.branch_location);
    pb["branch_locations"] = branchLocations;
  });

  /**
   * @type {LoanOfficer[]}
   */
  const loanOfficers = [...strapiLoanOfficers, ...strapiMortgageBankers];

  /**
   * @type {BranchLocation[]}
   */
  const allBranchLocations = flatMap(pageData.allStrapiBranchLocations.nodes, (branchLocation) => {
    if (typeof branchLocation.id === "string") {
      // convert string id to an Integer
      branchLocation.id = parseInt(branchLocation.id.replace("Branch-locations_", ""));
    }
    return branchLocation;
  });

  /**
   * USE CURRENT LOCATION HANDLER
   * @param {event} e
   */
  const handleCurrentLocation = function (e) {
    if (e) {
      e.preventDefault();
    }

    if (typeof window !== "undefined") {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          function (position) {
            // Clear error on new search
            setLocationSearchErrorMessage(null);
            setError(null);
            Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              function (response) {
                const geocodeResult = extractDataFromGoogleMapApiResponse(response);
                if (isValidGeocodeResult(geocodeResult)) {
                  // Update the search input with the user's location (city, state)
                  locationSearchInputRef.current.value = getFormattedSearchText(geocodeResult);
                }
                doSearchWithGeocodeResponse(geocodeResult);
              },
              (error) => {
                /* eslint-disable-next-line */
                console.error(error);

                setLocationSearchErrorMessage(
                  'Current location not available. Please check your device has "share location" enabled.'
                );
                setError('Current location not available. Please check your device has "share location" enabled.');
                clearDataOnError();
              }
            );
          },
          function () {
            // We were not able to access the user's location.
            // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
            setLocationSearchErrorMessage(
              'Current location not available. Please check your device has "share location" enabled.'
            );
            setError('Current location not available. Please check your device has "share location" enabled.');
            clearDataOnError();
          }
        );
      }
    }
  };

  /**
   * @param response
   * @return {GeocodeResult}
   */
  const extractDataFromGoogleMapApiResponse = (response) => {
    // TODO: can we replace <a href> with <Link to> here? Maybe not, this just gets passed as a string?
    const defaultErrorMessage = isSpanish
      ? `Esta búsqueda arrojó 0 resultados. Busque por ciudad, estado o código postal.`
      : props.isModalSearch
      ? `We're sorry! You are outside of our loan coverage area.`
      : `This search returned 0 results. Search by City, State or ZIP Code.`;

    if (response.results.length === 0) {
      // This search returned no results, send back an error message so we can inform the user.
      return {
        city: null,
        state: null,
        lat: null,
        lng: null,
        errorHtml: defaultErrorMessage
      };
    } else {
      // This search appears to have some results. Let's attempt to extrapolate the data.

      /**
       * @type {string|null}
       */
      let city = null;
      /**
       * @type {string|null}
       */
      let state = null;

      const result = response.results[0];
      // Get the city and state from the Google Maps API results
      if (result.address_components) {
        const cityAddressComponent = getAddressComponentsByType(result.address_components, "locality");
        if (cityAddressComponent.length > 0) {
          city = cityAddressComponent[0]["short_name"];
        }

        const stateAddressComponent = getAddressComponentsByType(
          result.address_components,
          "administrative_area_level_1"
        );
        if (stateAddressComponent.length > 0) {
          state = stateAddressComponent[0]["short_name"];
        }
      }

      if (state !== null && !isWaFdBankInState(state)) {
        // WaFd Bank is not in this state, send back an error message so we can inform the user.
        return {
          city: city,
          state: state,
          lat: null,
          lng: null,
          errorHtml: defaultErrorMessage
        };
      }

      let { lat, lng } = result.geometry.location;
      if (isFunction(lat) && isFunction(lng)) {
        lat = lat();
        lng = lng();
      }
      // We now have all the data we need. Send this data back to be further processed.
      return {
        city: city,
        state: state,
        lat: lat,
        lng: lng,
        errorHtml: null
      };
    }
  };

  /**
   * @param {string} locationSearchText
   * @return {Promise}
   */
  const reverseGeocode = (locationSearchText) => {
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage("en");
    return Geocode.fromAddress(locationSearchText);
  };

  /**
   * @param {GeocodeResult} geocodeResult
   * @return {boolean}
   */
  const isValidGeocodeResult = function (geocodeResult) {
    // For a geocode result to be valid, we should have values in the state, lat, and lng fields.
    return geocodeResult.state !== null && geocodeResult.lat !== null && geocodeResult.lng !== null;
  };

  /**
   *
   * @param {Object[]} loanOfficers
   * @returns {Object[]} BranchLocations
   */
  function getBranchLocationsFromLoanOfficers(loanOfficers, nameSearch = false) {
    // This function gives us a set (no duplicates) of the branch locations that this loan officer is associated with.
    // Much easier to use a map / dictionary and grab its values than try to handle de-duplication / uniqueness logic ourselves
    const branchLocationsById = {};
    loanOfficers.forEach((loanOfficer, index) => {
      for (let i = 0; i < loanOfficer.branch_locations.length; i++) {
        const branchLocation = loanOfficer.branch_locations[i];
        branchLocationsById[nameSearch ? index : branchLocation.id] = branchLocation;
      }
    });
    return Object.values(branchLocationsById);
  }

  /**
   * Returns an object, keyed by branch id, of an array of loan officers by branch.
   * @param {LoanOfficer[]} loanOfficers
   * @return {Object}
   */
  function getBranchLocationsLoanOfficers(loanOfficers) {
    const _branchLocations = {};
    // Business Rule: Some loan officers may have multiple branch locations, some branch locations may have multiple loan officers.
    for (let i = 0; i < loanOfficers.length; i++) {
      const loanOfficer = loanOfficers[i];
      if (true || (loanOfficer.EccUsername !== null && loanOfficer.EccUsername.trim() !== "")) {
        loanOfficer.branch_locations.forEach(function (loBranch) {
          // If branchLocations[loBranch.id;] is falsy (null or undefined), initialize it as an empty array, otherwise noop.
          _branchLocations[loBranch.id] = _branchLocations[loBranch.id] || [];
          _branchLocations[loBranch.id].push(loanOfficer);
        });
      }
    }

    const sortByNmlsr = (a, b) => {
      // Strings were sorting incorrectly, so being very explicit on the sort function here
      let aInt = parseInt(a.NMLSR);
      let bInt = parseInt(b.NMLSR);
      return aInt > bInt ? 1 : aInt < bInt ? -1 : 0;
    };

    // Sort all loan officers by job title:
    // 1. ALL "Mortgage Bankers" (may be more than one)
    // 2. "Branch Manager" (if one exists, but there should never be more than one)
    // 3. All others sorted by NMLSR number
    for (const branchId in _branchLocations) {
      const loanOfficers = [..._branchLocations[branchId]];
      const mortgageBankers = loanOfficers.filter((lo) => lo.JobTitle === "MortgageBanker");
      const branchManager = loanOfficers.filter((lo) => lo.JobTitle === "BranchManager");
      const personalBankers = loanOfficers
        .filter((lo) => lo.JobTitle !== "MortgageBanker" && lo.JobTitle !== "BranchManager")
        .sort((a, b) => sortByNmlsr(a, b));

      _branchLocations[branchId] = [...mortgageBankers, ...branchManager, ...personalBankers];
    }
    return _branchLocations;
  }

  /**
   * Build the GM Marker InfoWindow
   * @param {Object} branchLocation
   * @param {number} markerNumber
   * @returns redered markup
   */
  const buildMarkerInfoWindowHtml = function (branchLocation, markerNumber) {
    if (!branchLocation) {
      // eslint-disable-next-line no-console
      console.warn("buildMarkerInfoWindowHtml: No branchLocation provided.", markerNumber);
      return;
    }

    // Google Map InfoWindow API is not aware of JSX / React; it needs to be passed a string of HTML
    // https://developers.google.com/maps/documentation/javascript/reference/info-window

    /* window.___navigate seems to be provided by Gatsby and points to the @reach/router navigate function.
     * https://www.gatsbyjs.com/docs/production-app/#production-appjs
     * https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby/cache-dir/navigation.js#L170
     * So far this (along with CSS property scroll-behavior: smooth) is the only thing that allows smooth scrolling to work.
     * This may break if Gatsby ever changes this function. We could instead manually set navigate to something on the
     * Window so it can be called in the onclick, but I don't want to cause global namespace pollution on the `Window` object.
     */
    return `<div class="lead">
        ${branchLocation.Title}
        <hr style="margin-top: 0.75rem; margin-bottom: 0.75rem" />
        <a
          class="btn btn-sm btn-block btn-primary"
          title="See Loan Officers"
          onclick="window.___navigate('#branch-location-${markerNumber}');"
        >
          ${isSpanish ? "Ver Oficials de préstamos" : "See Loan Officers"}
        </a>
      </div>
    `;
  };

  // Builds map marker data objects to be passed to the GoogleMapSimple component
  const buildMapMarkersForBranchLocations = function (_branchLocations) {
    return _branchLocations.map(function (branchLocation, idx) {
      return {
        iconClass: "text-success mr-3",
        icon: "wafd",
        lat: branchLocation.Address.BranchGeoLocation.Lat,
        lng: branchLocation.Address.BranchGeoLocation.Lng,
        number: idx + 1,
        // When a marker is clicked, close any open InfoWindows and opens a new one on the clicked marker.
        onClick: function (number, marker) {
          if (googleMapInstance === null) {
            // eslint-disable-next-line no-console
            console.warn("onClick: No googleMapInstance", number, marker);
            return;
          }

          // Remove any other InfoWindows that are open
          if (openGoogleMapInfoWindow !== null) {
            openGoogleMapInfoWindow.setMap(null);
            openGoogleMapInfoWindow = null;
          }

          const infoWindow = new window.google.maps.InfoWindow({
            content: buildMarkerInfoWindowHtml(branchLocation, number)
          });
          infoWindow.open(googleMapInstance, marker);
          openGoogleMapInfoWindow = infoWindow;
        }
      };
    });
  };

  /**
   * Get the maximum distance in miles
   * @param {*} geocodeResult
   * @returns number
   */
  let MAX_DISTANCE_MILES = function (geocodeResult) {
    for (let i = 0; i < allBranchLocations.length; i++) {
      if (geocodeResult.city === allBranchLocations[i]?.branch_city?.CityName) {
        // if there is a branch location in the search text "city"
        return 25.0;
      } else if ((geocodeResult.city === "Austin" && geocodeResult.state === "TX") || props.isModalSearch === true) {
        return 250.0;
      }
    }
    // no branch location in the city
    return 50.0;
  };

  /**
   * Filters loan officers in a geographical area (geocodeResult contains lat,lng), with a radius of `maxDistanceMiles` miles.
   * @param {LoanOfficer[]} loanOfficers
   * @param {GeocodeResult} geocodeResult
   * @param {number} [maxDistanceMiles]
   * @returns {LoanOfficer[]}
   */
  const filterLoanOfficersByLocation = function (loanOfficers, geocodeResult, maxDistanceMiles) {
    // If maxDistanceMiles is not specified, use the default.
    maxDistanceMiles = maxDistanceMiles || MAX_DISTANCE_MILES(geocodeResult);

    // We need to account for users entering a state without entering a city. Show the entire state if only state is specified.
    const queryIncludesStateButNotCity = !geocodeResult.city && geocodeResult.state;

    // Loan officers who match our criteria will be stored here
    let filteredLoanOfficers = [];
    loanOfficers.forEach((loanOfficer) => {
      // If loanOfficer instance is null or undefined (not sure why it would be), do a quick fail.
      // If loanOfficer is not associated with any branch locations, this loan officer will not be shown on search results.
      // NOTE: there are 2 loan officers at TX421 which (purposefully) do not have a Branch Location in Strapi so we do not want to display them.
      if (loanOfficer?.branch_locations?.length === 0 || loanOfficer?.branch_locations[0] === null) {
        console.warn("branch location undefined for LO: ", loanOfficer);
        return;
      }

      loanOfficer.branch_locations = loanOfficer.branch_locations.map(function (branchLocation) {
        // Calculate the distance in miles from the user's search to the branch location
        branchLocation.Distance = distance(
          branchLocation.Address.BranchGeoLocation.Lat,
          branchLocation.Address.BranchGeoLocation.Lng,
          geocodeResult.lat,
          geocodeResult.lng,
          "M"
        );
        return branchLocation;
      });
      // Don't show branch locations outside of the search radius, even if this loan officer works at them
      loanOfficer.branch_locations = loanOfficer.branch_locations.filter(function (branchLocation) {
        return branchLocation.Distance <= maxDistanceMiles || queryIncludesStateButNotCity;
      });

      for (let i = 0; i < loanOfficer.branch_locations.length; i++) {
        const branchLocation = loanOfficer.branch_locations[i];
        const distanceMiles = branchLocation.Distance;
        // If distance exceeds the maximum distance, it should not be shown unless we are showing results for an entire state.
        if (distanceMiles <= maxDistanceMiles || queryIncludesStateButNotCity) {
          if (queryIncludesStateButNotCity) {
            if (geocodeResult.state !== getStateCodeFromState(branchLocation.Address.State)) {
              continue;
            }
          }

          loanOfficer.Distance = distanceMiles;
          filteredLoanOfficers.push(loanOfficer);
          break;
        }
      }
    });

    // Sort by distance ascending
    filteredLoanOfficers = filteredLoanOfficers.sort(function (e1, e2) {
      const e1d = e1.Distance;
      const e2d = e2.Distance;
      if (e1d < e2d) {
        return -1;
      } else if (e1d === e2d) {
        return 0;
      } else {
        return 1;
      }
    });

    // Truncate precision to one digit (e.g. 1.111111 becomes 1.1)
    filteredLoanOfficers = filteredLoanOfficers.map(function (value) {
      value.Distance = value.Distance.toFixed(1);
      return value;
    });

    // Limit how many branch locations are shown. Does not take distance into consideration.
    if (MAX_BRANCH_LOCATIONS_TO_SHOW > 0) {
      let _branchLocations = sortBy(getBranchLocationsFromLoanOfficers(filteredLoanOfficers), "Distance");
      if (!queryIncludesStateButNotCity && _branchLocations.length > MAX_BRANCH_LOCATIONS_TO_SHOW) {
        // https://lodash.com/docs/4.17.15#intersection
        _branchLocations = _branchLocations.slice(0, MAX_BRANCH_LOCATIONS_TO_SHOW);

        filteredLoanOfficers = filteredLoanOfficers.filter(function (loanOfficer) {
          return intersection(flatMap(loanOfficer.branch_locations, "id"), flatMap(_branchLocations, "id")).length > 0;
        });
      }
    }

    // We are done processing. Return the loan officers that met our search criteria.
    return filteredLoanOfficers;
  };

  /**
   * Perform a GM search with the passed Geocode response
   * @param {response} geocodeResponse
   */
  function doSearchWithGeocodeResponse(geocodeResponse) {
    if (isValidGeocodeResult(geocodeResponse)) {
      // Filter loan officers
      const filteredLoanOfficers = filterLoanOfficersByLocation(
        cloneDeep(loanOfficers),
        geocodeResponse,
        MAX_DISTANCE_MILES(geocodeResponse)
      );

      const _branchLocations = sortBy(getBranchLocationsFromLoanOfficers(filteredLoanOfficers), "Distance");
      updateSearchState(filteredLoanOfficers, _branchLocations);

      if (props.isModalSearch) {
        let loanOfficersForABranch = null;

        // Loop through the sorted branch locations to find eligible loan officers based on loan type
        // Business Rules:
        // HELOC, HELOAN (HomeEquity) Loans are done by Non MBs
        // Pre Approval - Only done by MBs
        // Other Loan Types - MBs get priority over any other LOs
        for (let i = 0; i < _branchLocations.length; i++) {
          const officersForBranch = getBranchLocationsLoanOfficers(filteredLoanOfficers)[_branchLocations[i].id];

          // Check if there are loan officers for this branch
          if (officersForBranch && officersForBranch.length > 0) {
            if (props.preApproval) {
              // If preApproval is true, assign only Mortgage Bankers
              const mortgageBankers = officersForBranch.filter(
                (loanOfficer) => loanOfficer.JobTitle === "MortgageBanker"
              );

              if (mortgageBankers.length > 0) {
                loanOfficersForABranch = mortgageBankers;
                break;
              }
            } else if (props.loanType === "HELOC" || props.loanType === "HELOAN") {
              // For HELOC/HELOAN loans: Filter out MBs
              const nonMortgageBankers = officersForBranch.filter(
                (loanOfficer) => loanOfficer.JobTitle !== "MortgageBanker"
              );

              // If we find non-MBs, assign them and break the loop
              if (nonMortgageBankers.length > 0) {
                loanOfficersForABranch = nonMortgageBankers;
                break;
              }
            } else {
              // For other loan types: Prioritize MBs, fallback to others if none found
              const mortgageBankers = officersForBranch.filter(
                (loanOfficer) => loanOfficer.JobTitle === "MortgageBanker"
              );

              // If MBs are available, use them
              if (mortgageBankers.length > 0) {
                loanOfficersForABranch = mortgageBankers;
                break;
              }

              // If no MBs, fallback to any available loan officers
              loanOfficersForABranch = officersForBranch;
            }
          }
        }

        // If no loan officers have been assigned (for fallback or no valid officers found)
        if (!loanOfficersForABranch || loanOfficersForABranch.length === 0) {
          for (let i = 0; i < _branchLocations.length; i++) {
            const officersForBranch = getBranchLocationsLoanOfficers(filteredLoanOfficers)[_branchLocations[i].id];
            if (officersForBranch && officersForBranch.length > 0) {
              loanOfficersForABranch = officersForBranch; // Use any available officers
              break;
            }
          }
        }

        if (typeof window !== "undefined") {
          window.location.href = props.preApproval
            ? `/personal-banking/home-loans/pre-approval?lar=${loanOfficersForABranch[0].EccUsername}`
            : props.loanType === "HELOC" || props.loanType === "HELOAN"
            ? `/personal-banking/home-loans/home-equity?lar=${loanOfficersForABranch[0].EccUsername}`
            : `/personal-banking/home-loans/mortgage-application?lar=${loanOfficersForABranch[0].EccUsername}`;
        }
      }

      if (filteredLoanOfficers.length > 0) {
        // We're not in an error state, reset any potential error conditions
        setLocationSearchErrorMessage(null);
        setError(null);
      } else {
        setLocationSearchErrorMessage(
          isSpanish
            ? `Esta búsqueda arrojó 0 resultados. Busque por ciudad, estado o código postal.`
            : props.isModalSearch
            ? "We're sorry! You are outside of our loan coverage area."
            : `This search returned 0 results. Search by City, State or ZIP Code.`
        );
        setError(
          isSpanish
            ? `Esta búsqueda arrojó 0 resultados. Busque por ciudad, estado o código postal.`
            : props.isModalSearch
            ? "We're sorry! You are outside of our loan coverage area."
            : `This search returned 0 results. Search by City, State or ZIP Code.`
        );
        // TODO: I don't believe that utag is in scope here. Please confirm by testing
        utag.link({
          tealium_event: "gatsby-error",
          error_type: "form-error",
          error_message: "This Loan Officer search returned 0 results. Search by City, State or ZIP Code."
        });
        clearDataOnError();
      }
    } else if (geocodeResponse.errorHtml) {
      setLocationSearchErrorMessage(geocodeResponse.errorHtml);
      setError(geocodeResponse.errorHtml);
      clearDataOnError();
    }
  }

  // Wrapper to get the location search text from the input value
  function initiateLocationSearch(searchText) {
    initiateLocationSearchWithSearchText(searchText);
  }

  /**
   * DO THE SEARCH BY LOCATION
   * @param {String} locationSearchText
   */
  function initiateLocationSearchWithSearchText(locationSearchText) {
    window.history.replaceState(null, "", `${props.location.pathname}?locationSearchText=${locationSearchText}`);
    setSearch(locationSearchText);
    setLocationSearchErrorMessage(null);
    setError(null);

    // Request address information (e.g. lat, lng) from Google Maps API from locationSearchText
    reverseGeocode(locationSearchText)
      .then(function (result) {
        // Collect the data we need (e.g. city, state) from the Google Maps API response and process it
        doSearchWithGeocodeResponse(extractDataFromGoogleMapApiResponse(result));
      })
      .catch(function (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        setLocationSearchErrorMessage(
          isSpanish
            ? `Un error ocurrio y no pudimos resolver una ubicación según los criterios de búsqueda. Busque por ciudad, estado o código postal.`
            : props.isModalSearch
            ? `Please enter a valid zip code`
            : `An error occurred and we were unable to resolve a location given the search criteria. Search by City, State or ZIP Code.`
        );
        clearDataOnError();
        setError(
          isSpanish
            ? `Un error ocurrio y no pudimos resolver una ubicación según los criterios de búsqueda. Busque por ciudad, estado o código postal.`
            : props.isModalSearch
            ? `Please enter a valid zip code`
            : `An error occurred and we were unable to resolve a location given the search criteria. Search by City, State or ZIP Code.`
        );
      });
  }

  // Wrapper to get the name search text from the input value
  function initiateNameSearch(searchText) {
    // Do some basic validation on the name search text
    if (searchText.length >= 2 && searchText.match(/^[a-z ,.'-]+$/i)) {
      initiateNameSearchWithSearchText(searchText);
    } else {
      setNameSearchErrorMessage(
        isSpanish
          ? `Ingrese un nombre con 2 o más letras o caracteres válidos.` // TODO: translate into Spanish"
          : `Please enter a Name with 2 or more letters or valid characters.`
      );
    }
  }

  /**
   * DO SEARCH BY NAME
   */
  function initiateNameSearchWithSearchText(nameSearchText) {
    // eslint-disable-next-line no-console
    // console.log(`nameSearchText: "${nameSearchText}"`);

    window.history.replaceState(null, "", `${props.location.pathname}?nameSearchText=${nameSearchText}`);
    setNameSearchErrorMessage(null);
    setSearch(nameSearchText);

    let loanOfficersExactMatches = [];
    let loanOfficersSortedByDistance = [];
    const nameIsExactMatch = new RegExp(`^${nameSearchText}$`, "gi");
    const nameStartsWithRegex = new RegExp(`^${nameSearchText}`, "gi");
    const nameSearchRegex = new RegExp(`${nameSearchText}`, "gi");

    let matchingLoanOfficers = loanOfficers.filter((officer) => {
      return `${officer.FirstName} ${officer.LastName}`.match(nameSearchRegex);
    });

    matchingLoanOfficers.map((matchedLoanOfficer) => {
      // If the loan officers first or last name starts with the search text, add them to the beginning of the array, otherwise add them to the end of the array so that they are sorted by relevance of the matching search text.

      if (matchedLoanOfficer.FirstName.match(nameIsExactMatch)) {
        loanOfficersExactMatches.unshift(matchedLoanOfficer);
      } else if (
        matchedLoanOfficer.FirstName.match(nameStartsWithRegex) ||
        matchedLoanOfficer.LastName.match(nameStartsWithRegex)
      ) {
        loanOfficersSortedByDistance.unshift(matchedLoanOfficer);
      } else {
        loanOfficersSortedByDistance.push(matchedLoanOfficer);
      }
    });

    loanOfficersSortedByDistance = [...loanOfficersExactMatches, ...loanOfficersSortedByDistance];

    const _branchLocations = sortBy(getBranchLocationsFromLoanOfficers(loanOfficersSortedByDistance), "Distance");
    updateSearchState(loanOfficersSortedByDistance, _branchLocations);

    if (loanOfficersSortedByDistance.length > 0) {
      // We're not in an error state, reset any potential error conditions
      setNameSearchErrorMessage(null);
      setError(null);
    } else {
      setNameSearchErrorMessage(
        isSpanish
          ? `Esta búsqueda arrojó 0 resultados.` // TODO: translate into Spanish
          : `This search returned 0 results. Please try a different name or spelling.`
      );
      clearDataOnError();
      setError(
        isSpanish
          ? `Esta búsqueda arrojó 0 resultados.` // TODO: translate into Spanish
          : `This search returned 0 results. Please try a different name or spelling.`
      );
    }
  }

  // reset all data objects after an error
  function clearDataOnError() {
    setBranchLocations([]);
    setLoanOfficersByBranchId({});
    setGoogleMapData({
      ...defaultGoogleMapDataProps,
      markers: []
    });
  }

  // format the search text by "city, state" or state code
  function getFormattedSearchText(geocodeResult) {
    if (geocodeResult.state) {
      if (geocodeResult.city) {
        return geocodeResult.city + ", " + geocodeResult.state;
      } else {
        return getStateFromStateCode(geocodeResult.state);
      }
    }
  }

  // Update the component state (not the location state) of the search
  const updateSearchState = function (_loanOfficers, _branchLocations) {
    setHasPerformedSearch(true);
    setBranchLocations(_branchLocations);
    setLoanOfficersByBranchId(getBranchLocationsLoanOfficers(_loanOfficers));

    setGoogleMapData({
      ...defaultGoogleMapDataProps,
      markers: buildMapMarkersForBranchLocations(_branchLocations)
    });
  };

  // HANDLE SEARCH INSIDE THE VIEWPORT
  const doSearchBranchLocationsByViewport = function () {
    const bounds = googleMapInstance.getBounds();
    searchParams.append("searchArea", true);

    window.history.replaceState(
      null,
      "",
      `${props.location.pathname}?locationSearchText=${locationSearchInputRef.current.value.trim()}&searchArea=true`
    );
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const center = googleMapInstance.getCenter();

    let _filteredBranchLocations = filterBranchLocationsByViewport(
      allBranchLocations,
      sw.lat(),
      sw.lng(),
      ne.lat(),
      ne.lng()
    );

    const _branchLocationLoanOfficers = getBranchLocationsLoanOfficers(loanOfficers);

    // Remove branch locations that don't have any loan officers
    _filteredBranchLocations = _filteredBranchLocations.filter((branchLocation) => {
      if (!has(_branchLocationLoanOfficers, branchLocation.id)) {
        return false;
      }

      const arr = _branchLocationLoanOfficers[branchLocation.id];
      return isArray(arr) && arr.length > 0;
    });

    // Sort by distance from the center of the map
    _filteredBranchLocations = sortBy(_filteredBranchLocations, (branchLocation) => {
      return distance(
        branchLocation.Address.BranchGeoLocation.Lat,
        branchLocation.Address.BranchGeoLocation.Lng,
        center.lat(),
        center.lng(),
        "M"
      );
    });

    // Get the loan officers from these branch locations
    const getBranchLocationIds = function () {
      return flatMap(_filteredBranchLocations, "id");
    };
    let _filteredLoanOfficers = cloneDeep(loanOfficers).filter((loanOfficer) => {
      return intersection(flatMap(loanOfficer.branch_locations, "id"), getBranchLocationIds()).length > 0;
    });

    // Limit how many branch locations are shown. Does not take distance into consideration.
    if (MAX_BRANCH_LOCATIONS_TO_SHOW > 0) {
      if (_filteredBranchLocations.length > MAX_BRANCH_LOCATIONS_TO_SHOW) {
        _filteredBranchLocations = _filteredBranchLocations.slice(0, MAX_BRANCH_LOCATIONS_TO_SHOW);

        _filteredLoanOfficers = _filteredLoanOfficers.filter(function (loanOfficer) {
          // https://lodash.com/docs/4.17.15#intersection
          return intersection(flatMap(loanOfficer.branch_locations, "id"), getBranchLocationIds()).length > 0;
        });
      }
    }
    localStorage.setItem("filteredLoanOfficers", JSON.stringify(_filteredLoanOfficers));
    localStorage.setItem("filteredBranchLocations", JSON.stringify(_filteredBranchLocations));
    updateSearchState(_filteredLoanOfficers, _filteredBranchLocations);
  };

  // ON PAGE LOAD
  useEffect(() => {
    // NOTE: that "props.location.search" is the actual window.location property for the search
    // (query string in the URL) parameter and has nothing to do with search by location or by name.
    if (props.location.search) {
      const locationSearchText = searchParams.get("locationSearchText")
        ? decodeURIComponent(searchParams.get("locationSearchText"))
        : null;
      const nameSearchText = searchParams.get("nameSearchText")
        ? decodeURIComponent(searchParams.get("nameSearchText"))
        : null;

      // put the search text into the inputs
      if (locationSearchText && locationSearchText !== undefined) {
        // locationSearchInputRef.current.value = locationSearchText;
        // nameSearchInputRef.current.value = "";
        setSearchType(0);
        locationSearchInputRef.current.focus();
        setQuery(locationSearchText);
        setSearch(locationSearchText);
      } else if (nameSearchText && nameSearchText !== undefined) {
        // nameSearchInputRef.current.value = nameSearchText;
        // locationSearchInputRef.current.value = "";
        setSearchType(1);
        nameSearchInputRef.current.focus();
        setSearch(nameSearchText);
        setQuery(nameSearchText);
      } else {
        // nameSearchInputRef.current.value = "";
        // locationSearchInputRef.current.value = "";
        setSearch("");
        setQuery("");
      }

      // get filtered branch locations and filtered loan officers from local storage
      const searchArea = searchParams.has("searchArea");
      const isCurrentLocationSearched = searchParams.has("currentLocationSearch");

      // update the searches with the filtered data or search text
      if (searchArea) {
        const filteredBranchLocations = localStorage.getItem("filteredBranchLocations")
          ? JSON.parse(localStorage.getItem("filteredBranchLocations"))
          : null;
        const filteredLoanOfficers = localStorage.getItem("filteredLoanOfficers")
          ? JSON.parse(localStorage.getItem("filteredLoanOfficers"))
          : null;
        if (filteredLoanOfficers && filteredBranchLocations) {
          updateSearchState(filteredLoanOfficers, filteredBranchLocations);
        }
      } else if (locationSearchText && locationSearchText !== undefined) {
        // console.log("THE LOCATION SEARCH: ", locationSearchText);
        setSearchType(0);
        locationSearchInputRef.current.focus();
        setSearch(locationSearchText);
        setQuery(locationSearchText);
        initiateLocationSearchWithSearchText(locationSearchText);
      } else if (nameSearchText && nameSearchText !== undefined) {
        // console.log("THE NAME SEARCH: ", nameSearchText);
        setSearchType(1);
        nameSearchInputRef.current.focus();
        setSearch(nameSearchText);
        setQuery(nameSearchText);
        initiateNameSearchWithSearchText(nameSearchText);
      } else if (isCurrentLocationSearched) {
        handleCurrentLocation();
      }
    }
  }, []);

  // Watch the location search input and the GM Instance for changes
  useEffect(() => {
    if (!googleMapInstance) {
      return;
    }

    // There will be no locationSearchInputRef if search box is hidden (e.g. state pages)
    if (!locationSearchInputRef.current) {
      return;
    }

    const options = {
      componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "icon", "name"],
      types: ["(regions)"]
    };

    // https://developers.google.com/maps/documentation/javascript/places-autocomplete
    const autocomplete = new window.google.maps.places.Autocomplete(locationSearchInputRef.current, options);
    autocomplete.addListener("place_changed", () => {
      setQuery(locationSearchInputRef.current.value);
      setSearch(locationSearchInputRef.current.value);
      window.history.replaceState(
        null,
        "",
        `${props.location.pathname}?locationSearchText=${locationSearchInputRef.current.value}`
      );
      const place = autocomplete.getPlace();
      /* If the user enters text and just hits 'Enter' and does not select from the Google Places Autocomplete dropdown,
       * the `place` object will only have a `name` property.
       * https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete.place_changed
       */
      if (place && place.geometry && place.geometry.location) {
        setLocationSearchErrorMessage(null);
        setError(null);

        const geocodeResult = extractDataFromGoogleMapApiResponse({
          results: [place]
        });
        doSearchWithGeocodeResponse(geocodeResult);
      } else if (place.name) {
        // Use the `place.name` as the search text and initiate the search.
        initiateLocationSearchWithSearchText(place.name);
      }
    });

    // TODO: why are we returning an empty (clean up) arrow function?
    return () => {};
  }, [locationSearchInputRef, googleMapInstance]);

  const [query, setQuery] = useState("");

  const [search, setSearch] = useState("");

  const doSearch = (searchText, searchType) => {
    if (searchType === 0) {
      initiateLocationSearch(searchText);
    } else {
      initiateNameSearch(searchText);
    }
  };

  const [searchType, setSearchType] = useState(0); // 0 For main search type and 1 for secondary search type

  let [error, setError] = useState(null);

  const buildErrorContainer = function () {
    if (error !== null) {
      return <NotificationAlert bodyText={error} type="danger" />;
    }
  };

  const handleRadioChange = (e) => {
    if (searchType === 1) {
      setSearchType(0);
      locationSearchInputRef.current.focus();
    } else {
      setSearchType(1);
      nameSearchInputRef.current.focus();
    }
    e.target.blur();
    setQuery("");
    setSearch("");
  };

  const searchByLabel = isSpanish ? "Buscar por:" : "Search by:";
  const locationLabel = isSpanish ? "Lugar" : "Location";
  const loanOfficerNameLabel = isSpanish ? "Nombre del Oficial de Préstamo" : "Loan Officer Name";
  const useCurrentLocationLabel = isSpanish ? "Usar Ubicación Actual" : "Use Current Location";
  const searchByLocationHint = isSpanish
    ? "Buscar por Ciudad, Estado o Código Postal"
    : "Search by City, State, or ZIP Code";
  const searchByNameHint = isSpanish ? "Buscar por Nombre o Apellido" : "Search by First or Last Name";
  return (
    <>
      {props.isModalSearch ? (
        <>
          <Modal id="loan-officer-search-modal" show={props.show} onHide={props.handleClose} size="md">
            <Modal.Header closeButton>
              <Modal.Title className="h3-font-size font-weight-bold text-success">
                {isSpanish ? "Empecemos" : "Let's Get Started"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <h4>{isSpanish ? "Por favor ingrese su código postal." : "Please enter your zip code."}</h4>
              <div className={`mb-2 ${locationSearchErrorMessage ? "text-red" : "text-black"}`}>
                {isSpanish ? "Código Postal" : "ZIP Code"}
              </div>
              <div className="input-group w-50">
                {/* Search Text Input */}
                <input
                  ref={locationSearchInputRef}
                  type="text"
                  name="locationSearchText"
                  id="locationSearchText"
                  placeholder=""
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      initiateLocationSearch(e.target.value);
                    }
                  }}
                  style={{ borderRadius: "6px" }}
                  className={`form-control ${locationSearchErrorMessage ? "alert-danger" : ""}`}
                />
              </div>
              {/* Error Message */}
              {locationSearchErrorMessage && (
                <div className="text-red mt-2" dangerouslySetInnerHTML={{ __html: locationSearchErrorMessage }} />
              )}
              {/* Use Current Location Button */}
              <div className="mt-2">
                <a
                  id="use-current-location"
                  href="/#"
                  className="text-decoration-none"
                  title="Use current location to search for loan officers near you"
                  onClick={(e) => {
                    window.history.replaceState(null, "", `${props.location.pathname}?currentLocationSearch=true`);
                    handleCurrentLocation(e);
                  }}
                >
                  <Icon name="map-marker-alt" class="mr-2" lib="fas" />
                  {isSpanish ? "Usar Ubicación Actual" : "Use Current Location"}
                </a>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="btn btn-link no-min-width" id="cancel-modal-cta" onClick={props.handleClose}>
                {isSpanish ? "Cancelar" : "Cancel"}
              </div>
              {/* "Submit" Button */}
              <button
                type="button"
                className="btn btn-primary no-min-width"
                id="goLocationSearchButton"
                onClick={() => initiateLocationSearch(locationSearchInputRef.current.value)}
              >
                {isSpanish ? "Enviar" : "Submit"}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <section className="container" id="loan-officer-search-container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <h1>
                  {isSpanish
                    ? `Encuentra un oficial de préstamo cerca de ti` // TODO: update Spanish translation
                    : `Find a Loan Officer`}
                </h1>
                <h4 className="text-success">
                  {isSpanish
                    ? `Es fácil hacer una cita con su oficial de préstamos local o iniciar una solicitud de préstamo en línea.` // TODO: update Spanish translation
                    : `It's easy to make an appointment with a loan officer or start your loan application online.`}
                </h4>

                <div className="row position-relative">
                  <div className="mx-3 w-100">
                    <h4 className="mt-2 font-weight-semi-bold" style={{ paddingLeft: 3 }}>
                      {searchByLabel}
                    </h4>
                    <FormGroup className="row mx-0">
                      <Form.Check
                        tabIndex={0}
                        inline
                        label={locationLabel}
                        name="principal"
                        type={"radio"}
                        id={`inline-radio-1`}
                        value={0}
                        checked={searchType === 0}
                        onChange={(e) => handleRadioChange(e)}
                      />
                      <Form.Check
                        tabIndex={0}
                        inline
                        label={loanOfficerNameLabel}
                        name="secondary"
                        type={"radio"}
                        id={`inline-radio-2`}
                        style={{ marginTop: 0, marginRight: 0 }}
                        value={1}
                        checked={searchType === 1}
                        onChange={(e) => handleRadioChange(e)}
                      />
                    </FormGroup>
                  </div>
                  <div className={"col-12 col-sm-12 col-md-11 col-lg-10"}>
                    {buildErrorContainer()}
                    {searchType === 0 && (
                      <div className="ml-1">
                        <a
                          id="use-current-location"
                          href="/#"
                          className="text-decoration-none"
                          title="Use current location to search for loan officers near you"
                          onClick={(e) => {
                            handleCurrentLocation(e);
                          }}
                        >
                          <Icon name="map-marker-alt" class="mr-2" lib="fas" />
                          {useCurrentLocationLabel}
                        </a>
                      </div>
                    )}
                    <div className="row mx-0 justify-content-between">
                      <p className={"mb-0 mt-2 text-gray-60"} style={{ paddingLeft: 3 }}>
                        {searchType === 0 ? searchByLocationHint : searchByNameHint}
                      </p>
                    </div>
                    <form key={0} className={(searchType === 0 ? "d-flex" : "d-none") + " row"}>
                      <div className={`col-12 row my-2 mb-3 mx-0 px-0`}>
                        <div className={`${styles.searchInputContainer} d-flex flex-grow-1`}>
                          <input
                            id="ssi-search-input-by-location"
                            name="ssi-search-input-by-location"
                            placeholder=""
                            type="text"
                            className={`${styles.searchInput} w-100 ml-2 py-0 rounded-0 border-0`}
                            style={{ outline: "none" }}
                            tabIndex={0}
                            autoComplete="off"
                            ref={locationSearchInputRef}
                            value={query}
                            onChange={(event) => {
                              setQuery(event.target.value);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" && query.length > 0) {
                                event.preventDefault();
                                if (query !== search) {
                                  doSearch(query, searchType);
                                }
                              }
                            }}
                          />
                          <button
                            tabIndex={0}
                            onClick={(e) => {
                              e.preventDefault();
                              locationSearchInputRef.current.focus();
                              setQuery("");
                            }}
                            className={`py-2 d-inline-block ${styles.buttonClearText}`}
                          >
                            <Icon name="times" class="fa-md text-secondary" />
                          </button>
                        </div>
                        <button
                          tabIndex={0}
                          onClick={(e) => {
                            e.preventDefault();
                            if (query !== search) {
                              if (query !== "") {
                                setSearch(query);
                                doSearch(query, searchType);
                              }
                            }
                          }}
                          className={`${styles.searchInputButton} btn btn-primary no-min-width`}
                          id="search-by-location-go-button"
                        >
                          <p className="mb-0 font-weight-bold">{isSpanish ? "Ir" : "Go"}</p>
                          <span className="sr-only">Search</span>
                        </button>
                      </div>
                    </form>
                    <form key={1} className={(searchType === 1 ? "d-flex" : "d-none") + " row"}>
                      <div className={`col-12 row my-2 mb-3 mx-0 px-0`}>
                        <div className={`${styles.searchInputContainer} d-flex flex-grow-1`}>
                          <input
                            id="ssi-search-input-by-name"
                            name="ssi-search-input-by-name"
                            type="text"
                            className={`${styles.searchInput} w-100 ml-2 py-0 rounded-0 border-0`}
                            style={{ outline: "none" }}
                            tabIndex={0}
                            autoComplete="off"
                            ref={nameSearchInputRef}
                            value={query}
                            onChange={(event) => {
                              setQuery(event.target.value);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" && query.length > 0) {
                                event.preventDefault();
                                if (query !== search) {
                                  doSearch(query, searchType);
                                }
                              }
                            }}
                          />
                          <button
                            tabIndex={0}
                            onClick={(e) => {
                              e.preventDefault();
                              nameSearchInputRef.current.focus();
                              setQuery("");
                            }}
                            className={`py-2 d-inline-block ${styles.buttonClearText}`}
                          >
                            <Icon name="times" class="fa-md text-secondary" />
                          </button>
                        </div>
                        <button
                          tabIndex={0}
                          onClick={(e) => {
                            e.preventDefault();
                            if (query !== search) {
                              if (query !== "") {
                                setSearch(query);
                                doSearch(query, searchType);
                              }
                            }
                          }}
                          className={`${styles.searchInputButton} btn btn-primary no-min-width`}
                          id="search-by-name-go-button"
                        >
                          <p className="mb-0 font-weight-bold">{isSpanish ? "Ir" : "Go"}</p>
                          <span className="sr-only">Search</span>
                        </button>
                      </div>
                    </form>
                    <NotificationAlert
                      bodyText={
                        isSpanish
                          ? "Asegúrese de elegir un oficial de préstamos en el mismo estado que la propiedad que desea financiar."
                          : "Be sure to choose a loan officer in the same state as the property you want to finance."
                      }
                      type="info"
                      id="loan-officer-search-state-alert"
                      class="mt-3"
                    />
                  </div>
                </div>
              </div>
              {/* -------------------- SEARCH BY VIEWPORT -------------------- */}
              <div className="col-12 col-md-6 col-lg-6">
                <GoogleMapSimple {...googleMapData} />

                {hasPerformedSearch && (
                  <div className="text-center mt-3">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        doSearchBranchLocationsByViewport();
                      }}
                    >
                      {isSpanish ? `Busca Este Lugar` : `Search this Area`}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
          {/* -------------------- SEARCH RESULTS -------------------- */}
          {hasPerformedSearch && (
            <section className="container">
              {/* Business rules:
             - Branch locations must only be shown once
             - Branch locations may have multiple loan officers associated with them
             - A loan officer may be associated with one or more branches
          */}

              {/* Loop through all branches with loan officers */}
              {branchLocations &&
                branchLocations.map((branchLocation, idx) => {
                  if (!branchLocation) {
                    // We don't have a branch location instance!
                    // eslint-disable-next-line no-console
                    console.warn("No branchLocation instance, unable to render loan officers.");
                    return null;
                  }

                  const branchId = branchLocation.id;
                  const loanOfficers = loanOfficersByBranchId[branchId];
                  return (
                    <div className="row" key={"branch-location-" + branchLocation.id}>
                      <div className="col-lg-12">
                        <div
                          className="row align-items-baseline mb-2"
                          id={"branch-location-" + (idx + 1)}
                          style={{ scrollMarginTop: "115px" }}
                        >
                          <div className="m-sm-0 col-2 col-md-auto">
                            <NumberedGreenCircle number={idx + 1} />
                          </div>
                          <div className="col-10 col-md-auto">
                            <h2>{branchLocation.Title}</h2>
                          </div>
                        </div>

                        {loanOfficers &&
                          loanOfficers.map((loanOfficer) => {
                            /* <Fragment> is the same as <> but allows keys and attributes */
                            /* https://reactjs.org/docs/fragments.html#short-syntax */
                            return (
                              <Fragment key={loanOfficer.id + "-" + branchLocation.id}>
                                <div className="row">
                                  <div className="col-md-12 col-lg-12">
                                    <LoanOfficerSummary loanOfficer={loanOfficer} branchLocation={branchLocation} />
                                  </div>
                                </div>
                                {idx !== branchLocations.length - 1 ? <hr /> : null}
                              </Fragment>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </section>
          )}
        </>
      )}
    </>
  );
};

export default LoanOfficerSearch;

LoanOfficerSearch.defaultProps = {
  location: {
    pathname: "",
    search: ""
  },
  language: "en",
  isModalSearch: false,
  preApproval: false,
  loanType: "HELOC",
  handleClose: "", // close handler callback
  show: "" // open handler callback
};
