import React from "react";
import { Link } from "gatsby";
import Icon from "../custom-widgets/icon";
import getViewOnGoogleMapsUrl from "../../helpers/getViewOnGoogleMapsUrl";
import { useLanguageContext } from "../../contexts/language-context";
import BankerHeadshot from "../banker-headshot";

// Components
const LoanOfficerSummary = (props) => {
  const isSpanish = useLanguageContext();

  let jobTitle = props.loanOfficer.JobTitle;
  let spanishJobTitle;
  const newJobTitle = function () {
    switch (jobTitle) {
      case "BranchManager":
        jobTitle = "Branch Manager";
        spanishJobTitle = "Gerente de Sucursal";
        break;
      case "BranchManagerTrainee":
        jobTitle = "Branch Manager Trainee";
        spanishJobTitle = "Gerente entrenando de Sucursal";
        break;
      case "AssistantBranchManager":
        jobTitle = "Assistant Branch Manager";
        spanishJobTitle = "Asistente de Gerente Sucursal";
        break;
      case "PersonalBanker":
        jobTitle = "Personal Banker";
        spanishJobTitle = "Banquero Personal";
        break;
      case "MortgageBanker":
        jobTitle = "Mortgage Banker";
        spanishJobTitle = "Banquero Hipotecario";
        break;
      case "LoanOfficer":
        jobTitle = "Loan Officer";
        spanishJobTitle = "Oficial de préstamo";
        break;
      case "FirstTimeHomeBuyerSpecialist":
        jobTitle = "First Time Home Buyer Specialist";
        spanishJobTitle = "Especialista de Compras de Casa por Primera Vez";
        break;
      default:
        jobTitle = "";
        spanishJobTitle = "";
    }
  };

  newJobTitle();

  const shownPhoneNumber = props.loanOfficer.PhoneNumber || props.branchLocation.PhoneNumber;

  return (
    <>
      {/* NOTE: using inline styles here because importing a style file for 2 properties seems overkill */}
      <div style={{ transition: "all ease-in-out 0.3s" }}>
        <div className="row py-2 py-md-0">
          <div className="col-12 col-md-6">
            <div className="row py-2 py-md-4" id="loan-officer-info-section">
              <div className="col-12 col-md-4 col-lg-3">
                <div className="text-md-center">
                  <BankerHeadshot
                    email={props.loanOfficer.EmailAddress}
                    style={{ marginTop: "0.25rem" }}
                    aria-label={`WaFd Bank ${props.branchLocation.Title} Loan Officer ${props.loanOfficer.FirstName} ${props.loanOfficer.LastName}`}
                  />
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9">
                {/* Loan Officer Name */}
                <h3 className="mb-2">
                  <strong>
                    {props.loanOfficer.FirstName} {props.loanOfficer.LastName}
                  </strong>
                </h3>
                {/* Job Title */}
                <div className="mb-2 font-weight-bold">{isSpanish ? spanishJobTitle : jobTitle}</div>
                {/* NMLS ID */}
                <div className="mb-2">NMLS ID: {props.loanOfficer.NMLSR}</div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 py-2 py-md-4">
            {/* Branch Address */}
            <div className="mb-3">
              <a
                href={getViewOnGoogleMapsUrl(props.branchLocation)}
                target="_blank"
                rel="noopener noreferrer"
                className="d-block overflow-hidden text-decoration-none"
              >
                <Icon lib="far" name="map-marker-alt" class="mr-2" />
                {props.branchLocation.Address.StreetAddress} {props.branchLocation.Address.City},{" "}
                {props.branchLocation.Address.State === "NewMexico"
                  ? isSpanish
                    ? "Nuevo Mexico"
                    : "New Mexico"
                  : props.branchLocation.Address.State}{" "}
                {props.branchLocation.Address.ZipCode}
              </a>
            </div>
            {/* Branch Page Link */}
            <div className="mb-3">
              <Link
                to={
                  isSpanish
                    ? `/es/sucursales${props.branchLocation.PageURL.replace(/\/locations/, "").replace(
                        /new-mexico/,
                        "nuevo-mexico"
                      )}`
                    : `${props.branchLocation.PageURL}`
                }
                className="d-block overflow-hidden text-decoration-none"
              >
                <Icon lib="far" name="university" class="mr-2" />
                {isSpanish ? `Ver detalles de la sucursal` : `View branch details`}
              </Link>
            </div>
            {/* LO Phone Number */}
            <div className="mb-3">
              <a href={`tel:${shownPhoneNumber}`} className="d-block overflow-hidden text-decoration-none">
                <Icon lib="far" name="phone" class="mr-2" />
                {shownPhoneNumber}
              </a>
            </div>
            {/* LO Email Address */}
            <div className="mb-3">
              <a
                href={`mailto:${props.loanOfficer.EmailAddress}`}
                className="d-block overflow-hidden text-decoration-none"
              >
                <Icon lib="far" name="envelope-open-text" class="mr-2" />
                {props.loanOfficer.EmailAddress}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanOfficerSummary;

LoanOfficerSummary.defaultProps = {
  loanOfficer: {
    ProfilePhoto: {
      publicURL: null
    },
    FirstName: null,
    LastName: null,
    NMLSR: null,
    PhoneNumber: null
  },
  branchLocation: {
    Title: null,
    Address: {
      StreetAddress: null,
      City: null,
      State: null,
      ZipCode: null
    },
    MSBookingsBranchName: null
  }
};
